import React, { useEffect } from "react";
import Layout from "./layout/Layout";
import { Router, createHistory, LocationProvider } from "@reach/router";

import HomePage from "./pages/Home";
import Creator from "./pages/Creator";
import Resource from "./pages/Resource";
// import Learner from "./pages/Learner";
import Stakeholders from "./pages/Stakeholders";
import WalletSignUpPage from "./pages/WalletSignUpPage";
import WalletSignInPage from "./pages/WalletSignInPage";
import ConnectWallet from "./pages/ConnectWallet";
import Status from "./pages/Status";

import Create from "./pages/Create";
import Profile from "./pages/Profile";
import TotalValueAssets from "./pages/TotalValueAssets";
import PortFolio from "./pages/PortFolio";
import Undefined from "./pages/Undefined";
import AllTogether from "./pages/AllTogether";
import { AppProvider } from "./utils/context";
import "./styles/style.scss";

const Pages = () => {
  return (
    <Router>
      <HomePage path="/" />
      <Creator path="/creator" />
      {/* <Learner path="/learner" /> */}
      {/* <AllTogether path="/resources" /> */}
      <Resource path="/allTogether" />
      <Stakeholders path="/stakeholder" />
      <WalletSignUpPage path="/walletSignup" />
      <WalletSignInPage path="/walletSignin" />
      <ConnectWallet path="/connectWallet" />
      <Status path="/status/:page" />

      <Create path="/creator/mint" />
      <Profile path="/creator/account" />
      <TotalValueAssets path="/creator/totalValue" />
      <PortFolio path="/creator/portFolio" />
      <Undefined path="*" />
    </Router>
  );
};
export default function App() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://secure.intelligent-data-247.com/js/260711.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  // let history = createHistory(window);
  return (
    <>
      <LocationProvider>
        <AppProvider>
          <Layout>
            <Pages />
          </Layout>
        </AppProvider>
      </LocationProvider>
    </>
  );
}
