import React, { useEffect } from 'react';
import BasicModal from '../components/BasicModal';

import ProfileHolder from '../components/ProfileHolder/ProfileHolder';

function Profile() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="creator">
			<BasicModal />
			<ProfileHolder />
		</div>
	);
}

export default Profile;
