import React, { useEffect } from 'react';
import BasicModal from '../components/BasicModal';

import PortFolioHolder from '../components/PortFolioHolder/PortFolioHolder';

function PortFolio() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<div className="creator">
			<BasicModal />
			<PortFolioHolder />
		</div>
	);
}

export default PortFolio;
