import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  block: {
    height: '100%',
    backgroundColor: 'white',
    borderRadius: '6px',
    boxShadow: '0 16px 24px 2px rgb(0 0 0 / 14%)',
  },
  img: {
    width: '100px',
    height: '100px',
    display: 'block',
    margin: '0 auto',
    padding: '35px 0 20px 0',
  },
  imgZOpen: {
    // width: "170px",
    // height: "155px",
    // display: "block",
    // margin: "0 auto",
    // padding: "0",
    // transform: "translateY(11px)"
  },
  title: {
    textAlign: 'center',
    paddingTop: '0',
    marginBottom: '25px',
    fontFamily: 'Roboto, sans-serif',
  },
  blockLink: {
    textDecoration: 'none',
    color: 'black',
  },
}));

const BlockType = (props) => {
  const classes = useStyles();

  return (
    <>
      <a
        href={props.link}
        className={classes.blockLink}
        target="_blank"
        rel="noreferrer"
      >
        <div className={classes.block}>
          <img
            className={
              props.alt === 'OpenZeppelin'
                ? `${classes.img} ${classes.imgZOpen}`
                : classes.img
            }
            src={props.img}
            alt={props.alt}
          />
          <h3 className={classes.title}>{props.title}</h3>
        </div>
      </a>
    </>
  );
};

export default BlockType;
