import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { MoralisProvider } from 'react-moralis';
// import reportWebVitals from "./reportWebVitals";
import TagManager from 'react-gtm-module';

// const APP_ID = "EE0VJslP5AoR367faZbpdRcZE3uDyJ5VdEXLqIV7";
// const SERVER_URL = "https://oaepbzmlt5ly.usemoralis.com:2053/server";

const APP_ID = 'Ra5TzI8AtWDIYG7k88juzx3zhEr6YZ8ghVUx8F34';
const SERVER_URL = 'https://yi52hrws3oqw.usemoralis.com:2053/server';

const tagManagerArgs = {
  gtmId: 'G-CDX7YRJGBL',
};

TagManager.initialize(tagManagerArgs);
if (window.self === window.top) {
  ReactDOM.render(
    <React.Suspense fallback="Loading...">
      <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
        <App />
      </MoralisProvider>
    </React.Suspense>,
    document.getElementById('root')
  );
}

// TagManager.initialize(tagManagerArgs);
// ReactDOM.render(
//   <React.Suspense fallback="Loading...">
//     <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
//       <App />
//     </MoralisProvider>
//   </React.Suspense>,
//   document.getElementById('root')
// );

// reportWebVitals();
