import React, { useEffect, useMemo, useState, useCallback } from "react";

import { MdRecordVoiceOver } from "react-icons/md";
import { makeStyles, Switch } from "@material-ui/core";
import ReactHowler from "react-howler";
import Resizer from "react-image-file-resizer";
import DatePicker from "react-date-picker";
import { useDropzone } from "react-dropzone";

import createFormStyle from "./style/createFormStyle";
import ButtonCreate from "./ButtonCreate.js";
import WalletCreator from "./WalletCreator.js";
import upload from "./img/upload.svg";
import cloud from "./img/cloud.svg";
import voice from "./img/voice.wav";
import { FileUploader } from "react-drag-drop-files";
import { Howl } from "howler";
import audio from "./audio/imchi.mpeg";
import chainlink from "../../assets/images/Chainlink-Symbol.svg";
const fileTypes = ["mp4", "avi"];

const useStyles = makeStyles(createFormStyle);

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#e52d27",
  padding: "20px",
  margin: "10px 0",
  color: "white",
  borderWidth: "2",
  borderRadius: "2",
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  height: "170px",
};

export default function Navigate() {
  const classes = useStyles();

  const [cosData, setCosData] = useState();
  const [filename, setFileName] = useState();
  const [online, setOnline] = useState(false);
  const [credDeadline, setCredDeadline] = useState(false);
  const [onsite, setOnsite] = useState(false);
  const [exp, setExp] = useState(false);
  const [assessment, setAssessment] = useState(false);
  const [qualifications, setQualifications] = useState(false);
  const [audioPlay, setAudioPlay] = useState(false);
  const [title, setTitle] = useState("");
  const [img, setImg] = useState("");
  const [imgCredential, setImgCredential] = useState("");
  const [description, setDescription] = useState("");
  const [ischecked, setIsChecked] = useState(false);
  const [number, setNumber] = useState();
  const [digitalContent, setDigitalContent] = useState([]);
  const [qualificationForm, setQualificationsForm] = useState();
  const [date, setDate] = useState(new Date());
  const [dnd, setDndName] = useState();
  const [price, setPrice] = useState();
  const [balance, setBalance] = useState(0);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const cosData1 = [
    {
      img: img,
      title: title,
      description: description,
      text: digitalContent,
      img1: imgCredential,
      credential_deadline: credDeadline,
      online: online,
      onSite: onsite,
      workExperience: exp,
      assessment: assessment,
      qualification: qualifications,
    },
  ];

  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  useEffect(() => {
    if (qualifications === true) {
      cosData1[0].form = qualificationForm;
    } else if (credDeadline === true) {
      cosData1[0].date = date;
    }
    setCosData(cosData1);
  }, [
    qualificationForm,
    img,
    title,
    description,
    digitalContent,
    imgCredential,
    credDeadline,
    online,
    onsite,
    exp,
    assessment,
    qualifications,
    balance,
    price,
  ]);

  const fileChangedHandler = (
    e,
    maxWidth,
    maxHeight,
    setData,
    minWidth = 0,
    minHeight = 0
  ) => {
    var fileInput = false;
    if (e) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          e,
          maxWidth,
          maxHeight,
          "PNG",
          100,
          0,
          (uri) => {
            setData(uri);
          },
          "base64",
          minWidth,
          minHeight
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  const files = acceptedFiles.map((file) => {
    if (dnd === "NFT") {
      fileChangedHandler(file, 350, 350, setImg, 200, 200);
    } else if (dnd === "CRED") {
      fileChangedHandler(file, 1280, 960, setImgCredential);
    } else {
      cosData1[0].text = file;
    }
  });

  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    console.log("Luca here");
    setFile(file);
  };

  return (
    <div className={classes.formContainer}>
      <h2>Create</h2>
      <div className={classes.block}>
        <div>
          <h4>Your NFT Ampersand token</h4>
          <div className={classes.nftToken}>
            <label style={{ fontWeight: "bold" }}>Upload your avatar</label>
            <label>(350 x 350 PNG image)</label>
          </div>
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} htmlFor="file" type="file" id="file" />
            <img className={classes.uploadSvg} src={upload} alt="upload" />
            <p onClick={() => setDndName("NFT")}>
              Drag 'N' Drop one File Here, Or Click To Select File
            </p>
          </div>
        </div>
        <div>
          <label>Name</label>
          <input
            className={classes.createForm_input}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="name"
          />
        </div>

        <div>
          <div>
            <label>Description</label>
            <textarea
              className={classes.createForm_textarea}
              placeholder="description here"
              maxLength="420"
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className={classes.block}>
        <h4>Your Digital Content</h4>
        <div className={classes.nftToken}>
          <label style={{ fontWeight: "bold" }}>
            Upload Your Digital Content
          </label>
        </div>

        <div {...getRootProps({ style })}>
          {/* 					
					<label className={classes.top}>Upload Your Audio, Video and Text Educational Courses</label>
					<div {...getRootProps({ style })}>
						<input {...getInputProps()} type="file" />
						<img className={classes.uploadSvg} src={cloud} alt="upload" />
						<p onClick={() => setDndName('CONTENT')}>
							Drag 'n' drop some files here, or click to select files
						</p>
					</div> */}
          <img className={classes.uploadSvg} src={cloud} alt="upload" />
          <FileUploader
            classes="AVDrag"
            multiple={true}
            handleChange={handleChange}
            name="file"
            label="Drag 'N' Drop one File Here, Or Click To Select File"
          />
          {/* <p>{file ? `File name: ${file[0].name}` : "no files uploaded yet"}</p> */}
          <p>Text, Audio and Video</p>
        </div>
      </div>

      <div className={classes.block}>
        <h4>The Learner path</h4>

        <div style={{ textAlign: "center" }}>
          <label style={{ fontWeight: "bold" }}>
            Upload Your Learner Credential
          </label>
        </div>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <img className={classes.uploadSvg} src={cloud} alt="upload" />
          <p onClick={() => setDndName("CRED")}>
            Drag 'N' Drop one File Here, Or Click To Select File
          </p>
        </div>
        <div className={classes.switch_option}>
          <span
            className={
              credDeadline === false
                ? classes.switch_title
                : classes.switch_title_active
            }
          >
            Credential deadline
          </span>
          <Switch
            onClick={() => {
              setCredDeadline(!credDeadline);
            }}
            style={{ color: online ? "red" : "black" }}
          />
        </div>
        <div className={classes.switch_option}>
          <DatePicker
            onChange={(e) => setDate(e)}
            disabled={!credDeadline}
            value={date}
            format={"y-MM-dd"}
          />
        </div>
        <div className={classes.switch_option}>
          <span
            className={
              online === false
                ? classes.switch_title
                : classes.switch_title_active
            }
          >
            online
          </span>
          <Switch
            onClick={() => {
              setOnline(!online);
            }}
            style={{ color: online ? "red" : "black" }}
          />
        </div>
        <div className={classes.switch_option}>
          <span
            className={
              onsite === false
                ? classes.switch_title
                : classes.switch_title_active
            }
          >
            onsite
          </span>
          <Switch
            onClick={() => {
              setOnsite(!onsite);
            }}
            style={{ color: onsite ? "red" : "black" }}
          />
        </div>
        <div className={classes.switch_option}>
          <span
            className={
              exp === false ? classes.switch_title : classes.switch_title_active
            }
          >
            work experience
          </span>
          <Switch
            onClick={() => {
              setExp(!exp);
            }}
            style={{ color: exp ? "red" : "black" }}
          />
        </div>

        <div className={classes.switch_option}>
          <span
            className={
              assessment === false
                ? classes.switch_title
                : classes.switch_title_active
            }
          >
            assessment
          </span>
          <Switch
            onClick={() => {
              setAssessment(!assessment);
            }}
            style={{ color: assessment ? "red" : "black" }}
          />
        </div>
        <div className={classes.switch_option}>
          <span
            className={
              qualifications === false
                ? classes.switch_title
                : classes.switch_title_active
            }
          >
            qualifications framework level
          </span>
          <Switch
            onClick={() => {
              setQualifications(!qualifications);
            }}
            style={{ color: qualifications ? "red" : "black" }}
          />
        </div>
        <div>
          <input
            disabled={qualifications ? false : true}
            className={classes.createForm_input}
            onChange={(e) => setQualificationsForm(e.target.value)}
          />
        </div>
      </div>

      <div className={classes.block}>
        <h4>Mint</h4>
        <div>
          <div className={classes.voiceRecord}>
            <label>Royalties to pay by each learner</label>
            <div onClick={() => setAudioPlay(!audioPlay)}>
              <MdRecordVoiceOver
                color={`${audioPlay ? "red" : "grey"}`}
                size="1.4em"
              />
              <ReactHowler
                src={audio}
                // src="https://file.api.audio/demo.mp3"
                playing={audioPlay}
                onEnd={() => setAudioPlay(false)}
              />
            </div>
          </div>
          <input
            type="number"
            min="0"
            className={classes.createForm_input}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <div>
          <label>Number of NFT Ampersand tokens to mint</label>
          <input
            type="number"
            min="0"
            className={classes.createForm_input}
            value={number}
            onChange={(e) => {
              if (e.target.value <= 100000000) {
                setNumber(e.target.value);
                setBalance(e.target.value * 3);
              }
            }}
            maxLength={100000000}
          />
        </div>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div>
              <p
                style={{
                  marginBottom: "7px",
                  marginTop: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Powered by Chainlink Data Feeds
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "92px",
                    marginLeft: "10px",
                  }}
                >
                  <img
                    src={chainlink}
                    alt="chainlink"
                    style={{ width: "30%", marginRight: "9px" }}
                  />
                </div>
              </p>
            </div>
            <p className={classes.toRight}>
              price = {number && number > 0 ? `${number * 3} $` : "0 $"}
            </p>
          </div>
          <input
            type="checkbox"
            className={classes.acceptPrivacyCheck}
            onChange={() => setIsChecked(!ischecked)}
          />
          <p className={classes.acceptPrivacyParagraph}>
            By using the Service, you accept the Terms and Conditions.
          </p>
        </div>
        <div className={classes.createFormSave}>
          <ButtonCreate
            ischecked={ischecked}
            cosdata={cosData}
            img={filename}
            title={title}
            description={description}
            price={price}
            balance={balance}
            amount={number}
          >
            MINT
          </ButtonCreate>
        </div>

        <div className={classes.createFormBlockchainGrid}>
          <div>
            <WalletCreator />
          </div>
        </div>
      </div>
    </div>
  );
}
