import React from "react";
import { Container, Grid } from "@mui/material";
import { AboutWrap, AboutTextWrap, AboutTextHeading, AboutText } from "./style";

const AboutResource = () => {
  return (
    <AboutWrap>
      <Container fixed>
        <Grid container alignItems="top" spacing={4}>
          <Grid item xs={12} md={6.5}>
            <AboutTextWrap>
              <AboutTextHeading>
                Education, Inclusion and Diversity
              </AboutTextHeading>
              <AboutText>
                Together, reshape the way we work, learn and live.                                      
              </AboutText>
              <AboutText>
                Future is now. Do more than you ever thought possible. Have more
                impact than you ever imagined.
              </AboutText>
              <AboutText>
                Knowledge, learning and future of work are key challenges for
                the achievement of the Sustainable Development Goals.
              </AboutText>
            </AboutTextWrap>
          </Grid>
          <Grid
            item
            xs={12}
            md={5.5}
            justifySelf={"center"}
            sx={{ textAlign: "center" }}
          >
            <img src="images/AboutResource.svg" alt="Lillup" />
          </Grid>
        </Grid>
      </Container>
    </AboutWrap>
  );
};

export default AboutResource;
