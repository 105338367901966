import React, { useEffect } from "react";
import HeadingGoes from "../components/HeadingGoes";
import LatestNews from "../components/LatestNews";
import DemoRequest from "../components/RequestDemo";
import About from "../components/About";
import SocialMediaSection from "../components/SocialMediaSection";
import TwitterSection from "../components/TwitterSection";
import InstagramSection from "../components/InstagramSection";
import LinkedinSection from "../components/LinkedinSection";
function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <About />
      <SocialMediaSection />
      <LinkedinSection />
      <InstagramSection />
      <TwitterSection />
      <HeadingGoes />
      <DemoRequest />
      <LatestNews />
    </>
  );
}

export default HomePage;
