import React from "react";
import { Container, Box, Typography, Grid } from "@mui/material";
import twitter from "../../assets/images/twitter.jpeg";
import { Link } from "@reach/router";

export default function TwitterSection() {
  return (
    <Link to="/twitter">
      <Box
        sx={{
          paddingY: "10%",
          backgroundColor: `#55ACEE`,
          padding: "57px 0",
          marginBottom: "203px",
        }}
      >
        <Container fixed sx={{ position: "relative" }}>
          <Box
            sx={{
              textAlign: "center",
              marginBottom: "35px",
              height: "255px",
            }}
          >
            <Typography
              variant="h2"
              sx={{
                color: "white",
                fontSize: "26px",
                fontWeight: "700",
                lineHeight: "32.5px",
                textTransform: "uppercase",

                "@media(min-width: 780px)": {
                  fontSize: "36px",
                  lineHeight: "42.5px",
                },
              }}
            >
              &chain protocole brings who you are
            </Typography>
            <Box>
              <Typography
                variant="span"
                sx={{
                  height: "4px",
                  display: "inline-block",
                  width: "30px",
                  background: "#fff",
                  marginTop: "21px",
                }}
              ></Typography>
              <Typography
                variant="span"
                sx={{
                  height: "4px",
                  display: "inline-block",
                  width: "30px",
                  background: "#eb1f24",
                }}
              ></Typography>
              <Typography
                variant="span"
                sx={{
                  height: "4px",
                  display: "inline-block",
                  width: "30px",
                  background: "#2f1a31",
                }}
              ></Typography>
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              boxShadow: "0px 18px 31px rgba(203, 203, 203, 0.25)",
              borderRadius: "6px",
              left: "0",
              textAlign: "center",
              maxHeight: "333px",
              width: "420px",
              top: "165px",

              img: {
                width: "50%",
                maxHeight: "333px",
                margin: "0 auto",
                "@media(min-width: 780px)": {
                  width: "100%",
                },
              },
              "@media(min-width: 780px)": {
                left: "calc(36% - 3.3%)",
              },
            }}
          >
            {/* <Grid container justifyContent={"center"}>
            <Grid item xs={12} md={6}> */}
            <img
              src={twitter}
              alt="Twitter"
              // sx={{
              //   width: "50%",
              //   "@media(min-width: 780px)": {
              //     width: "100%",
              //   },
              // }}
            />
            {/* </Grid>
          </Grid> */}
          </Box>
        </Container>
      </Box>
    </Link>
  );
}
