import React, { useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { useMoralis } from 'react-moralis';
import TextField from '@mui/material/TextField';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { navigate, useParams } from '@reach/router';
import { AppContext } from '../../utils/context';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '1025px',
  minWidth: '400px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  p: 4,
};

export default function BasicModal() {
  const { isAuthenticated } = useMoralis();
  const { modalClicked, setModalClicked } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    setModalClicked(false);
  };
  const params = useParams();
  const handleClose = () => {
    setOpen(false);
    navigate(`/${params.page}`);
  };
  useEffect(() => {
    if (isAuthenticated && modalClicked) {
      handleOpen();
    }
  }, [isAuthenticated]);

  const initialValues = {
    password: '',
  };

  const validationSchema = yup.object().shape({
    password: yup.string().required('Required Password'),
  });

  const onSubmit = async (values) => {
    const password = values.password;

    if (password === 'Eyjafjallajökull') {
      setOpen(false);
    } else {
      navigate(`/${params.page}`);
      alert('wrong password');
    }

    formik.resetForm();
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          border: '0',
          '.MuiBackdrop-root': {
            ...(params.page === 'creator'
              ? {
                  backgroundColor: '#410E03',
                }
              : { backgroundColor: '#E6330B' }),
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ textAlign: 'end' }}>
            <Button onClick={handleClose} sx={{ padding: 0, minWidth: 'auto' }}>
              <CloseIcon color={'#fff'} />
            </Button>
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              textAlign: 'center',
              fontStyle: 'normal',
              fontWeight: 700,
              fontSize: '36px',
              lineHeight: '42px',
              textAlign: 'center',
              textTransform: 'uppercase',
              color: '#333333',
              '@media(maxWidth: 1199.9px)': {
                fontSize: '22px',
              },
            }}
          >
            Beta Status
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{
              mt: 2,
              textAlign: 'center',
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '20px',
              lineHeight: '28px',
              textAlign: 'center',
              '@media(maxWidth: 1199.9px)': {
                fontSize: '16px',
              },
            }}
          >
            Join us a Beta tester, contact us on Twitter
          </Typography>
          <Box sx={{ textAlign: 'center', margin: '5% 0' }}>
            <a
              href="https://twitter.com/LillupWorkplace"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="../images/lazChain.svg"
                alt="lazchain"
                loading="lazy"
                style={{ width: '200px' }}
              />
            </a>
          </Box>
          <Box
            component="form"
            onSubmit={formik.handleSubmit}
            sx={{ textAlign: 'center' }}
          >
            <TextField
              id="outlined-password-input"
              type="password"
              placeholder="Password to access"
              sx={{ width: '100%' }}
              name="password"
              formik={formik}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              autoComplete="off"
            />
            <Button
              type="submit"
              sx={{
                minWidth: '189px',
                height: '60px',
                background: '#eb1f24 !important',
                borderRadius: '10px !important',
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '22px',
                lineHeight: '26px',
                textAlign: 'center',
                color: '#ffffff !important',
                marginTop: '8% !important',

                '&:hover': {
                  color: '#eb1f24 !important',
                  background: 'rgba(235, 31, 36, 0.23) !important',
                },
                '@media (max-width: 1199.9px)': {
                  minWidth: '150px',
                  height: '45px',
                  fontSize: '16px',
                  borderRadius: '8px !important',
                },
              }}
            >
              Done
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
