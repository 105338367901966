import React, { useEffect } from 'react';
// import EmptyComponent from "../components/EmptyComponent/EmptyComponent";
import AboutResource from '../components/AboutResource/AboutResource';
import ResourceService from '../components/ResoucesService';
import LatestNews from '../components/LatestNews';

const Resource = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <AboutResource />
      <ResourceService />
      <LatestNews />
    </>
  );
};

export default Resource;
