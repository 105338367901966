import styled from "styled-components";

const LinkedinSectionStyle = {
  mainDiv: {
    height: "858px",
    position: "relative",
  },
  circlesLeft: {
    position: "absolute",
    top: "221px",
    zIndex: "0",
  },
  circlesRight: {
    position: "absolute",
    top: "221px",
    zIndex: "0",
    right: "0",
  },
};

export default LinkedinSectionStyle;

export const AnimationText = styled.div`
  overflow: hidden;
`;
